import { isArray } from '@ember/array';
import { debug } from '@ember/debug';
import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import { typeOf, isPresent } from '@ember/utils';
import { isTesting } from '@embroider/macros';
import { task } from 'ember-concurrency';

import SocialProfileModel from 'later/models/social-profile';
import { ErrorSeverity } from 'later/services/errors';
import { eraseCookie } from 'later/utils/cookie';
import { fetch } from 'later/utils/fetch';
import { isProduction } from 'later/utils/is-env';

import type RouterService from '@ember/routing/router-service';
import type UserAgentService from 'ember-useragent/services/user-agent';
import type AuthService from 'later/services/auth';
import type DatadogService from 'later/services/datadog';
import type ErrorsService from 'later/services/errors';
import type { TrackEventProperties } from 'later/services/log-rocket';
import type LogRocketService from 'later/services/log-rocket';
import type SubscriptionsService from 'later/services/subscriptions';
import type UserRoleService from 'later/services/user-role';
import type { UntypedService } from 'shared/types';
import type { JsonObject } from 'type-fest';

export interface UserOptions {
  id: number;
  name: string;
  email: string;
}

type AuthFields = {
  user?: string;
  user_id?: string;
  follower_count?: string;
  main_profile_industry?: string;
  main_profile_business_model?: string;
};

export default class SegmentService extends Service.extend(Evented) {
  @service declare auth: AuthService;
  @service declare datadog: DatadogService;
  @service declare errors: ErrorsService;
  @service declare locale: UntypedService;
  @service declare router: RouterService;
  @service declare subscriptions: SubscriptionsService;
  @service declare userAgent: UserAgentService;
  @service declare userRole: UserRoleService;
  @service declare logRocket: LogRocketService;

  previousURL: string | null = null;

  constructor(...args: Record<string, unknown>[]) {
    super(...args);

    if (window.analytics) {
      window.analytics.ready(() => {
        this.trigger('analyticsReady');
      });
    }

    /**
     * Records the previousURL by saving the URL before a route change
     * Initializes previousURL to document.referrer to track external redirects
     * Falls back to window.location.href if document.referrer is null, ie. when opening from bookmark
     * This ensures previousURL is always initialized to a non-null value
     *
     */
    this.router.on('routeWillChange', () => {
      const currentURL = window.location.href;
      this.previousURL = isPresent(this.previousURL) ? currentURL : document.referrer || currentURL;
    });

    /**
     * Sends a Page tracking event to Appcues. Only sends on if the `Apppcues` global is defined,
     * which is only available on Staging and Production.
     *
     */
    this.router.on('routeDidChange', () => {
      window.Appcues?.page();
    });
  }

  get isAnalyticsUndefined(): boolean {
    return typeof window.analytics === 'undefined';
  }

  /**
   * Sends a tracking event with metadata to Segment.io.
   * Only sends on if the `analytics` global is defined,
   * which is only available on Staging and Production. On other environments
   * this simply logs out what the event and data are.
   * Includes an optional integrations hash allowing for selective destination filtering.
   * [See docs](https://segment.com/docs/sources/website/analytics.js/#selecting-integrations)
   *
   * @param eventName The name of the event to track
   * @param payloadMetadata to send with Event
   * @param authFields fields to grab directly from `auth` service.
   * @param integrations An integrations object for selective destination filtering
   * @param options Other non-integrations options to pass to Segment.io
   */
  track(
    eventName: string,
    payload: JsonObject = {},
    authFields: AuthFields = {
      user: 'currentUserModel.id',
      user_id: 'currentUserModel.id'
    },
    integrations = {},
    options = {}
  ): void {
    const fullPayload = this._buildPayload(eventName, payload, authFields);
    const fullPayloadFormatted = this._formatPayload(fullPayload);

    const isAnyIntegrationTrue = Object.values(integrations).includes(true);

    const formattedOptions = {
      ...{
        integrations: {
          ...(isAnyIntegrationTrue ? { All: false } : {}),
          ...integrations
        }
      },
      ...options
    };

    if (!isProduction()) {
      try {
        debug(`track: ${eventName}`);
        debug('payload: ' + JSON.stringify(fullPayloadFormatted));
        debug('integrations & options: ' + JSON.stringify(formattedOptions));
      } catch (error) {
        this.errors.log(error, {}, ErrorSeverity.Info);
      }
    }

    this._trackDatadog(eventName, fullPayloadFormatted);

    if (this.isAnalyticsUndefined && !isTesting()) {
      return;
    }

    this._trackLogRocket(eventName, fullPayloadFormatted);
    this._trackSegment(eventName, fullPayloadFormatted, formattedOptions);
  }

  /**
   * Calls @track with given event and payload.
   * If there is an error, calls log from
   * the errors service, using the given errorCallback to generate the error.
   *
   * @param eventName The name of the event to track
   * @param payload Metadata to send with Event
   * @param errorCallback The callback method which generates the error message
   */
  trackWithError(eventName: string, payload: JsonObject = {}, errorCallback: (error: Error) => string): void {
    try {
      this.track(eventName, payload);
    } catch (error) {
      this.errors.log(new Error(errorCallback(error)), payload);
    }
  }

  /**
   * Fetches user and account traits JSON from segment_identify endpoint
   */
  fetchUserAndAccountTraits = task(async () => {
    try {
      const url = '/api/v2/users/segment_identify';
      const response = await fetch(url);
      response.user.language = this.locale.userLocaleCode; //remove once implemented by backend
      return response;
    } catch (error) {
      if (error?.code !== 0) {
        this.errors.log('Unable to fetch user/accounts traits', error);
      }
      throw error;
    }
  });

  /**
   * Calls identify and group with the response from fetchUserAndAccountTraits
   * Only sends on if the `analytics` global is defined,
   * which is only available on Staging and Production
   *
   * Gets called from after industry info is submitted on signup, because
   * after user submits their info on the previous screen, endpoint returns
   * a 401 unauthorized
   *
   */
  identify = task(async (userId: string, accountId: string) => {
    const response = await this.fetchUserAndAccountTraits.perform();

    if (this.isAnalyticsUndefined) {
      return;
    }
    this.logRocket.identify(userId, response?.user ?? {});
    window.analytics.identify(userId, response?.user, {});
    window.analytics.group(accountId, response?.account);
  });

  /**
   * Calls segment alias which links anonymous user to identified user
   * https://segment.com/docs/connections/spec/alias/
   * Only sends on if the `analytics` global is defined,
   * which is only available on Staging and Production
   *
   */
  setUserAlias(userId: number): void {
    if (this.isAnalyticsUndefined) {
      return;
    }
    try {
      window.analytics.ready(() => {
        const previousId = window.analytics.user().anonymousId();
        if (isPresent(userId) && isPresent(previousId)) {
          window.analytics.alias(userId, previousId);
        }
      });
    } catch (error) {
      this.errors.log('Unable to link anonymous user and identified user', error);
    }
  }

  /**
   * Calls segment identify for anonymous user
   * https://segment.com/docs/connections/spec/identify/
   * Only sends on if the `analytics` global is defined,
   * which is only available on Staging and Production
   *
   * @param options Object to send to Segment
   */
  identifyAnonymous(options: JsonObject): void {
    if (this.isAnalyticsUndefined) {
      return;
    }

    try {
      if (isPresent(options) && typeof options === 'object') {
        window.analytics.identify(options);
      }
    } catch (error) {
      this.errors.log('Unable to send anonymous user info', error);
    }
  }

  /**
   * Sends a reset call to analytics.reset() to clear out any activity
   * tracking for the user.
   *
   */
  reset(): void {
    if (this.isAnalyticsUndefined) {
      return; // ghost session
    }

    window.analytics.reset();
  }

  /**
   * Only used after an Ember Account creation to connect a user's previous mixpanel activities
   * to their newly created mixpanel identity.
   * [See docs](https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices)
   *
   * @param user Newly created User Model
   */
  trackAccountAlias(user: UserOptions): void {
    debug('alias: ' + JSON.stringify(user));

    if (
      window.mixpanel === undefined ||
      this.isAnalyticsUndefined ||
      typeof window.mixpanel.get_distinct_id === 'undefined'
    ) {
      return; // ghost session
    }

    window.analytics.alias(user.id, window.mixpanel.get_distinct_id(), {}, () => {
      // callback after alias completes
      window.analytics.identify(user.id, {
        name: user.name,
        email: user.email,
        onboard_flow: 'ember_self_cat'
      });
    });
  }

  /**
   * Sets Klaviyo's disable tracking cookie only if the `analytics` global is defined,
   * which is only available on Staging and Production
   *
   * Klaviyo tracking cannot distinguish between two different users on the same user agent.
   * If a person switches users, Klaviyo reports all analytics events as belonging to the first user.
   * As a workaround suggested by Klaviyo's support, we can erase the Klaviyo client library cookie
   * which will disable user tracking.  This will allow Segment's user tracking to be the source of truth.
   * See documentation: https://help.klaviyo.com/hc/en-us/articles/360034666712-About-Cookies-in-Klaviyo
   *
   */
  disableKlaviyoTracking(): void {
    const klaviyoCookie = '__kla_id';

    if (this.isAnalyticsUndefined) {
      return;
    }

    eraseCookie(klaviyoCookie);
  }

  /**
   * Grabs the GA4 session Id
   *
   */
  async getSessionId(): Promise<string | undefined> {
    return this._getGAMetric('session_id');
  }

  /**
   * Grabs the GA4 Session Number
   *
   */
  async getSessionNumber(): Promise<string | undefined> {
    return this._getGAMetric('session_number');
  }

  /**
   * Grabs the GA4 Client ID
   *
   */
  async getClientId(): Promise<string | undefined> {
    return this._getGAMetric('client_id');
  }

  /**
   * Compares the google client id from the GA cookie with the google client id from the server. If they differ, it
   * sets the googleClientId property on the user to the new client id from the GA cookie.
   *
   */
  async updateGoogleClientId(): Promise<void> {
    try {
      const currentUser = this.auth.currentUserModel;
      const cookieGoogleClientId = await this.getClientId();
      const serverGoogleClientId = currentUser.googleClientId;
      if (cookieGoogleClientId && cookieGoogleClientId !== serverGoogleClientId) {
        currentUser.set('googleClientId', cookieGoogleClientId);
        currentUser.save();
      }
    } catch (error) {
      this.errors.log(error);
    }
  }

  /**
   * Common function to get a GA4 metric; is defensive about whether gtag is loaded
   * @see https://segment.com/docs/connections/destinations/catalog/actions-google-analytics-4/#using-gtagjs-and-google-analytics-4-cloud-destination
   */
  async _getGAMetric(metric: string): Promise<string | undefined> {
    return new Promise((resolve) => {
      if (typeof (window as any).gtag === 'function') {
        const { gtag } = window as any;
        gtag('get', 'G-Y8EC3L18GE', metric, resolve);
      } else {
        console.warn('gtag is not defined');
        resolve(undefined);
      }
    });
  }

  /**
   * Sends a tracking event with metadata to Segment.io.
   * Only sends on if the `analytics` global is defined,
   * which is only available on Staging and Production. On other environments
   * this simply logs out what the event and data are.
   *
   * @param eventName The name of the event to track
   * @param payload Metadata to send with Event
   * @param options A hash of config options for the Event
   */
  _trackSegment(eventName: string, payload: JsonObject, options: JsonObject = {}): void {
    try {
      window.analytics?.track(eventName, payload, options);
    } catch (error) {
      this.errors.log(error);
    }
  }

  /**
   * Sends a tracking event with metadata to Datadog
   * Only sends on if datadog is present (handled in datadog service)
   *
   * This is a temporary-ish solution while we evaluate session replay in datadog.
   * A more long term solution will be to abstract an event tracking service, which sends
   * to segment and datadog (and any others).
   *
   * @param eventName The name of the event to track
   * @param payload Metadata to send with Event
   */
  _trackDatadog(eventName: string, payload: JsonObject): void {
    try {
      this.datadog.addAction(eventName, payload);
    } catch (error) {
      this.errors.log(error);
    }
  }

  _trackLogRocket(eventName: string, payload: JsonObject): void {
    const logRocketProperties: TrackEventProperties = Object.entries(payload).reduce<TrackEventProperties>(
      (acc, [key, value]) => {
        if (typeof value === 'undefined') {
          return acc;
        }
        acc[key] = typeof value === 'object' ? JSON.stringify(value) : value;
        return acc;
      },
      {}
    );
    this.logRocket.track(eventName, logRocketProperties);
  }

  /**
   * Builds the payload to be sent with a tracking event
   * Expands additional social profile attributes if a model has been passed via the payload.
   *
   * Make sure to update https://www.notion.so/latershome/058c2f3886464196995e0b153ec3405d?v=872ada39abf241cfa966081fe3897015&p=4292b80cc4b04a94bbd7942804f5710d
   * when making changes to the default payload.
   *
   */
  _buildPayload(eventName: string, payload: JsonObject, authFields: JsonObject): JsonObject {
    return {
      ...this._getAuthValues(authFields),
      ...this._getDefaultPayload(eventName),
      ...this.#getSocialProfilePayload(payload),
      ...payload
    };
  }

  /**
   * Converts string values in an object to their corresponding
   * variable in the `auth` service.
   *
   */
  _getAuthValues(fields: JsonObject): JsonObject {
    const authPayloadArray = Object.keys(fields).map((key) => {
      const value = fields[key];
      if (typeOf(value) !== 'string') {
        return {};
      }

      const authValue = this.get(`auth.${value}` as keyof SegmentService);
      return { [key]: authValue };
    });
    return Object.assign({}, ...authPayloadArray);
  }

  /**
   * Returns the default payload to be sent with every tracking event.
   *
   * Make sure to update https://www.notion.so/latershome/058c2f3886464196995e0b153ec3405d?v=872ada39abf241cfa966081fe3897015&p=4292b80cc4b04a94bbd7942804f5710d
   * when making changes to the set defaults.
   *
   */
  _getDefaultPayload(eventName: string): JsonObject {
    return {
      current_route_name: this.router.currentRouteName ? this.router.currentRouteName : 'No Route Defined',
      ...((eventName === 'viewed-page' || eventName === 'signed-up-for-account') && {
        previous_webpage_url: this.previousURL
      }),
      platform: 'web',
      has_creator: this.auth.currentAccount?.hasCreatorProfile,
      has_business: this.auth.currentAccount?.hasBusinessProfile,
      is_account_owner: this.userRole?.currentUser?.isAccountOwner || false,
      is_mobile_device: this.userAgent.device.isMobile,
      is_reviewer_on_group: this.userRole?.currentUser?.isApprover || false,
      plan_name: this.subscriptions.planName?.toLowerCase() ?? null,
      provider_status: this.subscriptions.subscription?.providerStatus,
      active_trial: this.subscriptions.hasActiveTrial,
      language: this.locale.userLocaleCode,
      later_app: 'Later_Social'
    };
  }

  /**
   * Returns the formatted payload so the event can be sent
   *
   */
  _formatPayload(fullPayload: JsonObject = {}): JsonObject {
    // add a stringified version of array values
    for (const key in fullPayload) {
      if (isArray(fullPayload[key])) {
        const newKey = `${key}_str`;
        fullPayload[newKey] = (fullPayload[key] as []).join();
      }
    }

    return fullPayload;
  }

  /**
   * Returns the curated social profile payload obtained by taking first social profile model found in payload
   * or auth.currentSocialProfile
   *
   */
  #getSocialProfilePayload(payload: JsonObject): JsonObject {
    const [key, socialProfile] = Object.entries(payload).find(([, value]) => value instanceof SocialProfileModel) || [];
    if (!(socialProfile || this.auth.currentSocialProfile)) {
      return {};
    }
    const { nickname, industry, id, businessModel, followedBy, profileType, instagramProfileType } = (socialProfile ||
      this.auth.currentSocialProfile) as SocialProfileModel;
    if (key) {
      delete payload[key];
    }
    return {
      nickname: nickname ?? null,
      industry: industry ?? null,
      social_profile: id,
      business_model: businessModel ?? null,
      follower_count: followedBy ?? null,
      profile_type: profileType ?? null,
      instagram_profile_type: instagramProfileType?.toLowerCase() ?? null
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    segment: SegmentService;
  }
}
