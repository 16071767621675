import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import LogRocket from 'logrocket';

import { isProduction } from 'later/utils/is-env';

import type AuthService from 'later/services/auth';

interface IUserTraits {
  active_trial: boolean;
  plan_name: string;
  has_active_subscription: boolean;
  sign_in_count: number;
  country: string;
  [propName: string]: string | number | boolean;
}

export type TrackEventProperties = {
  revenue?: number;
  [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined | null;
};

function isAnalyticsUndefined(): boolean {
  return typeof window.analytics === 'undefined';
}

export default class LogRocketService extends Service {
  @tracked userId: string | undefined;

  @service declare auth: AuthService;

  /**
   * Creates a new instance of the LogRocket service.
   * Automatically initializes LogRocket during service instantiation.
   */
  constructor(...args: Record<string, unknown>[]) {
    super(...args);
    if (!isAnalyticsUndefined()) {
      this.setupLogRocket();
    }
  }

  /**
   * Initializes LogRocket with our application ID.
   * Only initializes in production environments.
   */
  setupLogRocket(): void {
    if (!isProduction()) {
      return;
    }
    const internalDomains = ['@later.com', '@mavrck.co', '@joinmavely.com'];
    const email: string = this.auth.currentUserModel?.email ?? '';
    if (email && internalDomains.every((internalDomain) => !email.includes(internalDomain))) {
      LogRocket.init('nplwar/applatercom');
    }
  }

  /**
   * Identifies the current user within LogRocket.
   * Associates user traits with their identity for better session context.
   *
   * This method is typically called from the Segment service once user information is available.
   *
   * @param userId - The unique identifier for the current user
   * @param userTraits - Object containing user properties to associate with sessions
   */
  identify(userId: string, userTraits: IUserTraits): void {
    if (!isProduction()) {
      return;
    }

    const { active_trial, plan_name, has_active_subscription, sign_in_count, country } = userTraits;
    // Create a clean traits object for consistent use
    const identifyTraits = {
      active_trial,
      plan_name,
      has_active_subscription,
      sign_in_count,
      country
    };

    // Identify the user with LogRocket
    LogRocket.identify(userId, identifyTraits);

    // Track conditional events
    const events: Array<{ condition: boolean; eventName: string }> = [
      { condition: active_trial, eventName: 'active_trial' },
      { condition: has_active_subscription, eventName: 'has_active_subscription' },
      { condition: country === 'United States' || country === 'Canada', eventName: 'north_america' },
      { condition: sign_in_count <= 3, eventName: 'new_user' }
    ];

    // Log each relevant event
    events.forEach(({ condition, eventName }) => {
      if (condition) {
        LogRocket.track(eventName, identifyTraits);
      }
    });
  }

  /**
   * Tracks a custom event in LogRocket.
   * Only sends events in production environments.
   *
   * This method is typically called from the Segment service as part of unified analytics.
   *
   * @param eventName - Name of the event to track
   * @param payload - Properties to associate with the event
   */
  track(eventName: string, payload: TrackEventProperties): void {
    if (!isProduction()) {
      return;
    }

    LogRocket.track(eventName, payload);
  }
}

// Add TypeScript type declaration for the service
declare module '@ember/service' {
  interface Registry {
    'log-rocket': LogRocketService;
  }
}
